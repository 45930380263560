import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { NavigationItem } from 'lib/types/navigation.types';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';
import LinkTranslated from './Translator/LinkTranslated';
type NavItemProps = {
  navigation: NavigationItem;
  hasCloser: boolean;
};
import { ReactNode } from 'react';

function NavbarItemWrapper({
  children,
  hasCloser,
  classNames,
}: {
  children: ReactNode;
  hasCloser: boolean;
  classNames?: string;
}) {
  return hasCloser ? (
    <Disclosure.Button className={classNames}>{children}</Disclosure.Button>
  ) : (
    <>{children}</>
  );
}

const NavbarItem = function ({ navigation, hasCloser }: NavItemProps) {
  const { t } = useTranslation('common');
  return !navigation.subitems ? (
    <NavbarItemWrapper hasCloser={hasCloser} classNames='my-2'>
      <LinkTranslated
        href={navigation.href}
        aProps={{
          className: classNames(
            navigation.isCurrent
              ? 'bg-gray-900 text-white'
              : 'text-gray-500 hover:bg-gray-100 ',
            'rounded-md px-3 py-2 text-sm font-medium transition duration-200'
          ),
          'aria-current': navigation.isCurrent ? 'page' : 'false',
          'data-testid': navigation.href + (hasCloser ? '-mobile' : ''),
        }}
      >
        {t(navigation.name)}
      </LinkTranslated>
    </NavbarItemWrapper>
  ) : (
    <div className='dropdown dropdown-hover'>
      <label
        tabIndex={0}
        className={classNames(
          navigation.isCurrent
            ? 'bg-gray-900 text-white'
            : 'text-gray-500 hover:bg-gray-100',
          'rounded-md px-3 py-2 text-sm font-medium transition duration-200',
          'm-1 flex items-center'
        )}
      >
        {t(navigation.name)} <ChevronDownIcon className='h-4' />
      </label>
      <ul
        tabIndex={0}
        className='dropdown-content flex flex-col rounded-md bg-base-100 p-2 shadow'
      >
        {navigation.subitems.map((subitem) => (
          <NavbarItemWrapper hasCloser={hasCloser} key={subitem.href}>
            <LinkTranslated href={subitem.href}>
              <li
                className={classNames(
                  navigation.isCurrent
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-500 hover:bg-gray-100',
                  'cursor-pointer rounded-md px-3 py-2 text-sm font-medium transition duration-200'
                )}
              >
                {t(subitem.name)}
              </li>
            </LinkTranslated>
          </NavbarItemWrapper>
        ))}
      </ul>
    </div>
  );
};

export default NavbarItem;

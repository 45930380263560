import React, { useEffect } from 'react';
import Script from 'next/script';
import { Router } from 'next/router';
import { pageview } from '@/lib/utilities/gtm';

export function TagManager() {
  useEffect(() => {
    const handleRouteChange = (url: string) => pageview(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER ? (
    <Script
      id='gtm-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');

  function gtag(){window.dataLayer.push(arguments);}

`,
      }}
    />
  ) : null;
}

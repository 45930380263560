import { Disclosure } from '@headlessui/react';
import { VFC } from 'react';
import LanguageSelector from './LanguageSelector';
import Logo from './Logo';
import NavbarItem from './NavbarItem';
import NavbarToggleButton from './NavbarToggleButton';
import { navigation } from './navigation.constants';

const Navbar: VFC = () => {
  return (
    <header className='sticky top-0 z-50 bg-white'>
      <Disclosure as='nav' className='border-b-2 bg-white'>
        {({ open, close }) => (
          <>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <div className='flex h-16 items-center justify-between'>
                <NavbarToggleButton open={open} />
                <Logo />

                <div className='flex flex-row items-center lg:space-x-4'>
                  <div className='hidden lg:block'>
                    <div className='flex items-center lg:ml-10 lg:space-x-4'>
                      {navigation.map((item) => (
                        <NavbarItem
                          navigation={item}
                          key={item.href}
                          hasCloser={false}
                        />
                      ))}
                    </div>
                  </div>
                  <LanguageSelector />
                </div>

                <Disclosure.Panel className='px-4 pb-2 pt-4 text-sm text-gray-500'>
                  <div className='absolute  left-0 top-0 z-40 flex w-full flex-col items-center space-y-4 border-b-2 bg-white p-12'>
                    {navigation.map((item) => (
                      <div
                        onClick={() => {
                          close();
                        }}
                        key={item.href}
                      >
                        <NavbarItem navigation={item} hasCloser={true} />
                      </div>
                    ))}
                    <LanguageSelector />
                  </div>
                </Disclosure.Panel>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Navbar;

import useTranslation from 'next-translate/useTranslation';
import { Suspense, useState } from 'react';

import dynamic from 'next/dynamic';
import { Dialog } from '@headlessui/react';
import { SuccessCheck } from './SuccessCheck';

const DynamicNewsletterForm = dynamic(() => import('./NewsletterForm'), {
  suspense: true,
});

export function NewsletterModal() {
  const { t } = useTranslation('common');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className='flex w-full flex-col items-center bg-slate-900 p-4'>
        <label
          htmlFor='my-modal-4'
          className='modal-button w-66 btn h-24 max-w-xs rounded border-2 border-orange-400 bg-transparent px-4 py-2 text-xl font-bold text-orange-400 transition duration-200 hover:bg-orange-400 hover:text-slate-900'
          data-testid='btn-newsletter-modal'
          onClick={() => setIsOpen(true)}
        >
          {t('common:sign-up-button')}
        </label>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='relative z-50'
      >
        {/* backdrop shadow */}
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
        <div className='fixed inset-0 flex items-center justify-center p-4'>
          <Dialog.Panel className='modal-box w-full max-w-sm rounded bg-white'>
            <Dialog.Title>
              {' '}
              <h3 className='title text-center'>
                {t('common:Sign up for our email newsletter')}
              </h3>
            </Dialog.Title>
            <div className=' relative overflow-visible'>
              {!isSubmitted && isOpen ? (
                <>
                  <Suspense fallback={`Loading...`}>
                    <DynamicNewsletterForm setIsSubmitted={setIsSubmitted} />
                  </Suspense>
                </>
              ) : (
                <SuccessCheck message={t('common:Thank you for subscribing')} />
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}

import CookieConsentBanner from '@/components/Cookies/CookieConsentBanner';
import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';
import classNames from 'classnames';
import { ReactNode } from 'react';

/* eslint-disable no-unused-vars */
interface PropType {
  hasContainer: boolean;
  isFullScreen: boolean;
  children: ReactNode;
}

function MainLayout({ hasContainer, isFullScreen, children }: PropType) {
  return (
    <>
      <Navbar />
      <div className='min-h-full'>
        <main>
          <div
            className={classNames({
              'mx-auto': true,
              'max-w-7xl ': hasContainer,
              'py-6 sm:px-6 lg:px-8 ': !isFullScreen,
            })}
          >
            <div className={isFullScreen ? ' ' : 'px-4 py-6 sm:px-0'}>
              {children}
            </div>
          </div>
        </main>
        <CookieConsentBanner />
        <Footer />
      </div>
    </>
  );
}

export default MainLayout;

function getDomains(env) {
  if (env == 'development' || env == 'test') {
    return [
      {
        domain: 'dev.bevmaq.com',
        defaultLocale: 'en-US',
        http: true,
      },
      {
        domain: 'dev.bevmaq.fr',
        defaultLocale: 'fr-FR',
        http: true,
      },
      {
        domain: 'dev.bevmaq.de',
        defaultLocale: 'de-DE',
        http: true,
      },
      {
        domain: 'dev.bevmaq.es',
        defaultLocale: 'es-ES',
        http: true,
      },
      {
        domain: 'dev.bevmaq.pl',
        defaultLocale: 'pl-PL',
        http: true,
      },
      {
        domain: 'dev.bevmaq.it',
        defaultLocale: 'it-IT',
        http: true,
      },
      {
        domain: 'dev.bevmaq.cz',
        defaultLocale: 'cs-CZ',
        http: true,
      },
      {
        domain: 'dev.bevmaq.co.uk',
        defaultLocale: 'en-GB',
        http: true,
      },
    ];
  }
  if (env == 'staging') {
    return [
      {
        domain: 'staging.bevmaq.com',
        defaultLocale: 'en-US',
      },
      {
        domain: 'staging.bevmaq.de',
        defaultLocale: 'de-DE',
      },
      {
        domain: 'staging.bevmaq.fr',
        defaultLocale: 'fr-FR',
      },
      {
        domain: 'staging.bevmaq.es',
        defaultLocale: 'es-ES',
      },
      {
        domain: 'staging.bevmaq.pl',
        defaultLocale: 'pl-PL',
      },
      {
        domain: 'staging.bevmaq.it',
        defaultLocale: 'it-IT',
      },
      {
        domain: 'staging.bevmaq.cz',
        defaultLocale: 'cs-CZ',
      },
      {
        domain: 'staging.bevmaq.co.uk',
        defaultLocale: 'en-GB',
      },
    ];
  }
  if (env == 'production') {
    return [
      {
        domain: 'www.bevmaq.com',
        defaultLocale: 'en-US',
      },
      {
        domain: 'www.bevmaq.fr',
        defaultLocale: 'fr-FR',
      },
      {
        domain: 'www.bevmaq.de',
        defaultLocale: 'de-DE',
      },
      {
        domain: 'www.bevmaq.es',
        defaultLocale: 'es-ES',
      },
      {
        domain: 'www.bevmaq.pl',
        defaultLocale: 'pl-PL',
      },
      {
        domain: 'www.bevmaq.it',
        defaultLocale: 'it-IT',
      },
      {
        domain: 'www.bevmaq.cz',
        defaultLocale: 'cs-CZ',
      },
      {
        domain: 'www.bevmaq.co.uk',
        defaultLocale: 'en-GB',
      },
    ];
  }
  console.log('NO DOMAINS FOR ' + env);
}
exports.getDomains = getDomains;

import Link from 'next/link';
import Image from 'next/image';
import logo from '@/public/logo.png';
function Logo() {
  return (
    <div className='relative h-20 max-h-[90%] w-56 shrink-0 cursor-pointer'>
      <Link href='/'>
        <a>
          <Image src={logo} alt='Benvmaq' layout='fill' objectFit='contain' />
        </a>
      </Link>
    </div>
  );
}
export default Logo;

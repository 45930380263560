import {
  LanguagesI,
  pathTranslations,
  PathTranslationsI,
} from '../../lib/server/pathTranslations';

type Href =
  | string
  | { pathname: string; query: { [key: string]: string | number } };
export type Props = {
  href: Href;
};
export interface pathTranslation {
  [index: string]: {
    [index: string]: string;
  };
}

export function getTranslatedPath(
  href: Href,
  locale: LanguagesI
): string | undefined {
  const hasQueryParameters = typeof href !== 'string';
  const path = hasQueryParameters ? href.pathname : href;
  const noBackSlashPath = path !== '/' ? path.replace(/\/$/, '') : '/';
  const i18ns = pathTranslations?.[noBackSlashPath as keyof PathTranslationsI];
  let translatedPath =
    i18ns && locale in i18ns ? (i18ns[locale] as string) : path;
  if (hasQueryParameters) {
    const parameters = Object.keys(href.query);
    parameters.forEach((hrefParam) => {
      if (translatedPath !== undefined) {
        translatedPath = translatedPath.replace(
          `[${hrefParam}]`,
          href.query[hrefParam].toString()
        );
      }
    });
  }
  // if process.env.NEXT_PUBLIC_TRAILLING_SLASH=='true' than return translatedPath into the end of the string if there is none
  if (process.env.NEXT_PUBLIC_TRAILLING_SLASH === 'true') {
    if (translatedPath.endsWith('/')) {
      return translatedPath;
    } else {
      return `${translatedPath}/`;
    }
  } else {
    return translatedPath;
  }
}

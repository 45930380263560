import { LanguagesI } from '@/lib/server/pathTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { getTranslatedPath, Props } from './Href';

type onClick = React.MouseEvent<HTMLAnchorElement> | (() => void);

interface LinkTranslatedType extends React.PropsWithChildren<Props> {
  aProps?: { [key: string]: string | number | boolean | onClick };
  onClick?: onClick;
}
function LinkTranslated({
  href,
  children,
  aProps,
  onClick,
}: LinkTranslatedType) {
  const { locale } = useRouter();
  const as = getTranslatedPath(href, locale as LanguagesI);
  if (onClick != undefined && aProps != undefined) {
    aProps.onClick = onClick;
  }
  return (
    <Link href={href} as={as}>
      <a href={as} {...aProps}>
        {children}
      </a>
    </Link>
  );
}

export default LinkTranslated;

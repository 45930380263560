import useTranslation from 'next-translate/useTranslation';
import Logo from './Logo';
import { NewsletterModal } from './Form/NewsletterModal';
import LinkTranslated from './Translator/LinkTranslated';

function FooterLink(props: { link: { href: string; label: string } }) {
  return (
    <LinkTranslated href={props.link.href}>
      <h3 className='cursor-pointer hover:link'>{props.link.label}</h3>
    </LinkTranslated>
  );
}

function Footer() {
  const { t } = useTranslation('common');

  return (
    <>
      <NewsletterModal />
      <div className='tester absolute left-0 flex w-full flex-col items-center bg-gray2-100 p-4'>
        <div className='flex w-full max-w-4xl flex-col items-center  justify-between space-y-6 md:flex-row md:space-x-8'>
          <div>
            <Logo />
            <div className='flex flex-col items-center'>
              <h3 className='font-bold'>BEVMAQ GmbH</h3>
              <h3>Mühlenhorst 8</h3>
              <h3>49637 Menslage</h3>
            </div>
          </div>
          <div className='flex flex-col items-center'>
            {[
              {
                href: '/sell',
                label: t('Sell'),
              },
              {
                href: '/buy',
                label: t('Buy'),
              },
              {
                href: '/company',
                label: t('Company'),
              },
              {
                href: '/press',
                label: t('Press'),
              },
              {
                href: '/jobs',
                label: t('Jobs'),
              },
              {
                href: '/contact',
                label: t('Contact'),
              },
            ].map((link) => (
              <FooterLink key={link.href} link={link} />
            ))}
          </div>
          <div className='flex flex-col items-center'>
            {[
              {
                href: '/imprint',
                label: t('Imprint'),
              },
              {
                href: '/privacy',
                label: t('Privacy'),
              },
              {
                href: '/terms-and-conditions',
                label: 'T&C',
              },
            ].map((link) => (
              <FooterLink key={link.href} link={link} />
            ))}
          </div>
          <div>
            <a href='mailto:contact@bevmaq.com'>
              <h3 className='font-semibold'>contact@bevmaq.com</h3>
            </a>
            <a href='tel:+49 173 90 80 414'>
              <h3>+49 173 90 80 414</h3>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

import { Product } from '../types/bevmaq.types';

declare global {
  /* eslint-disable unused-imports/no-unused-vars */
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const pageview = (url: string): void => {
  pushDataLayer('pageview', {
    page: url,
  });
};

export const trackSubmitForm = ({
  category,
  action,
  label,
}: {
  category: string;
  action: string;
  label: string;
}): void => {
  pushDataLayer('formSubmission', {
    formType: category, // GA: cateogry
    eventProps: {
      category: category,
      action: action,
      label: label,
    },
  });
};

export function pushDataLayer(event: string, data: Record<string, any>): void {
  if (window.dataLayer?.push) {
    window.dataLayer.push({
      event,
      ...data,
    });
  } else {
    console.log(`error: dataLayer does not exist`);
  }
}

export function selectItem(product: Product): void {
  pushDataLayer('select_item', {
    ecommerce: {
      items: [getGTMProductData(product)],
    },
  });
}
export function getGTMProductData(product: Product): Record<string, any> {
  return {
    item_id: product.id,
    item_name: product.model.name,
    currency: product.price?.currency,
    item_brand: product.model.manufacturer.name,
    item_category: product.category.name,
    price: product.price?.amount,
    quantity: 1,
  };
}

const { pathTranslations } = require('./pathTranslations');
const { getDomains } = require('./getDomains');

const EXEMPLE = 'http://exemple.xyz';

function translateUrl(
  url,
  langOutput,
  langInput = 'en-US',
  concatDomain = false
) {
  let urlObj = getExempleUrl(url);
  let inputPath = urlObj.pathname;
  const englishRoutes = Object.keys(pathTranslations);

  // first search for exact match

  for (const enPath of englishRoutes) {
    const inputLangPath =
      langInput == 'en-US' ||
      typeof pathTranslations[enPath][langInput] == 'undefined'
        ? enPath
        : pathTranslations[enPath][langInput];
    if (inputPath.replace(/\/$/, '') === inputLangPath) {
      urlObj.pathname =
        (langOutput == 'en-US'
          ? enPath
          : pathTranslations[enPath][langOutput]) +
        (inputPath.match(/\/$/) ? '/' : '');
      const url = urlObj.href.replace(EXEMPLE, '');
      if (concatDomain) {
        const domain = getDomains(process.env.NEXT_PUBLIC_APP_ENV).find(
          (d) => d.defaultLocale == langOutput
        );
        const ret = domain?.domain
          ? (domain.http ? 'http://' : 'https://') +
            domain.domain +
            urlObj.pathname
          : url;
        return ret;
      }
      return url;
    }
  }

  // second, search for regex
  for (const enPath of englishRoutes) {
    const inputLangPath =
      langInput == 'en-US' ||
      typeof pathTranslations[enPath][langInput] == 'undefined'
        ? enPath
        : pathTranslations[enPath][langInput];

    const langInputRegexRoute = getRouteRegex(inputLangPath);
    if (inputPath.match(langInputRegexRoute)) {
      urlObj.pathname = urlObj.pathname.replace(
        new RegExp(langInputRegexRoute),
        (pathTranslations[enPath][langOutput] || enPath).replace(/\[.*\]/, '$1')
      );
      const url = urlObj.href.replace(EXEMPLE, '');
      if (concatDomain) {
        const domain = getDomains(process.env.NEXT_PUBLIC_APP_ENV).find(
          (d) => d.defaultLocale == langOutput
        );
        const ret = domain?.domain
          ? (domain.http ? 'http://' : 'https://') +
            domain.domain +
            urlObj.pathname
          : url;
        return ret;
      }
      return url;
    }
  }

  return urlObj.href.replace(EXEMPLE, '');

  function getExempleUrl(url) {
    let tdl = langOutput.split('-')[0];
    tdl = tdl == 'en' ? 'com' : tdl;
    const hasDomainInUrl = url[0] !== '/';
    if (!hasDomainInUrl) {
      url = EXEMPLE + url;
    }
    let translatedURL = url.replace(/\.com|\.fr|\.de/, '.' + tdl);
    let urlObj = new URL(translatedURL);
    return urlObj;
  }
}

function getRouteRegex(route) {
  return route.replace(/\[.*\]/, '(.*)');
}

function getLocaleFromTLD(url) {
  const urlObj = new URL(url);
  const tld = urlObj.hostname.split('.').slice(-1).pop();
  return tld == 'com' ? 'en-EN' : tld + '-' + tld.toUpperCase();
}

module.exports.translateUrl = translateUrl;
module.exports.getLocaleFromTLD = getLocaleFromTLD;

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Flags from 'country-flag-icons/react/3x2';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { getDomains } from '@/lib/server/getDomains';
import { translateUrl } from '@/lib/server/translateURL';
import { TbWorld } from 'react-icons/tb';

export default function LanguageSelector() {
  const router = useRouter();
  const locale = router.locale;
  const options = [
    {
      label: 'Global',
      locale: 'en-US',
      icon: <TbWorld title='Global' className='mr-2 text-xl' />,
      active: locale == 'en-US',
    },

    {
      label: 'CZ',
      locale: 'cs-CZ',
      icon: <Flags.CZ title='Czech Republic' className='mr-2 h-4' />,
      active: locale == 'cs-CZ',
    },
    {
      label: 'DE',
      locale: 'de-DE',
      icon: <Flags.DE title='Germany' className='mr-2 h-4' />,
      active: locale == 'de-DE',
    },
    {
      label: 'ES',
      locale: 'es-ES',
      icon: <Flags.ES title='Spain' className='mr-2 h-4' />,
      active: locale == 'es-ES',
    },
    {
      label: 'FR',
      locale: 'fr-FR',
      icon: <Flags.FR title='France' className='mr-2 h-4' />,
      active: locale === 'fr-FR',
    },
    {
      label: 'IT',
      locale: 'it-IT',
      icon: <Flags.IT title='Italy' className='mr-2 h-4' />,
      active: locale == 'it-IT',
    },
    {
      label: 'PL',
      locale: 'pl-PL',
      icon: <Flags.PL title='Poland' className='mr-2 h-4' />,
      active: locale == 'pl-PL',
    },
    {
      label: 'UK',
      locale: 'en-GB',
      icon: <Flags.GB title='United Kingdom' className='mr-2 h-4' />,
      active: locale == 'en-GB',
    },
  ];
  return (
    <div className='text-center lg:ml-auto lg:text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='flex w-full justify-center rounded-md border-2 px-4 py-2 text-sm font-medium hover:bg-opacity-30  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 md:space-x-2'>
            {options.find((o) => o.active)?.icon}
            <div className='hidden md:block'>
              {options.find((o) => o.active)?.label}
            </div>
            <ChevronDownIcon
              className='h-5 w-5 text-blue-200 hover:text-blue-100 md:mr-1'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-40 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white px-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-36'>
            <div className='py-1 md:px-1'>
              {options
                .filter((o) => !o.active)
                .map((o) => {
                  const langDomain = getDomains(
                    process.env.NEXT_PUBLIC_APP_ENV
                  )?.find((domain) => domain.defaultLocale == o.locale);

                  const url =
                    langDomain &&
                    'http' in langDomain &&
                    langDomain?.['http'] === true
                      ? 'http://' + langDomain['domain'] + ':3001'
                      : 'https://' + langDomain?.['domain'];

                  return (
                    <Menu.Item key={o.label}>
                      {({ active }) => {
                        return (
                          <a
                            href={translateUrl(
                              url + router.asPath,
                              o.locale,
                              router.locale
                            )}
                          >
                            <button
                              className={`${
                                active
                                  ? 'bg-blue-500 text-white'
                                  : 'text-gray-900'
                              } group flex w-full items-center rounded-md py-2 text-sm md:px-2 `}
                            >
                              {o.icon}
                              {o.label}
                            </button>
                          </a>
                        );
                      }}
                    </Menu.Item>
                  );
                })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

import { useAppDispatch } from '@/app/hooks';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { setHasCookieConsent } from './cookieConsentSlice';
import { getCookieConsentValue } from 'react-cookie-consent';

function CookieConsentBanner() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleAcceptCookie = () => {
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
      dispatch(setHasCookieConsent(true));
      updateGTMConsent(true);
    }
  };

  useEffect(() => {
    const cookieConsent = !!getCookieConsentValue();
    if (cookieConsent) {
      updateGTMConsent(cookieConsent);
    }
  }, [dispatch]);

  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
    updateGTMConsent(false);
  };

  return process.env.NEXT_PUBLIC_ENABLE_GOOGLE_ANALYTICS === 'true' ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CookieConsent
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      buttonText={t('common:Accept All')}
      buttonClasses='accept-cookies'
      declineButtonText={t('common:Reject All')}
      declineButtonClasses={'testid-decline-tracking decline-cookies'}
      buttonStyle={{
        background: 'blue',
        color: 'white',
      }}
    >
      {/* eslint-disable @next/next/no-html-link-for-pages */}
      <Trans
        i18nKey='common:gdpr-message'
        components={{
          link: <a className='text-blue-300' href={t('common:/privacy')} />,
        }}
      />
    </CookieConsent>
  ) : null;
}

export default CookieConsentBanner;

export const updateGTMConsent = (consent: boolean | 'yes' | 'no') => {
  consent = consent == 'yes' || consent == true;
  if (window.gtag) {
    window.gtag('consent', 'update', {
      ad_storage: consent ? 'granted' : 'denied',
      analytics_storage: consent ? 'granted' : 'denied',
      ad_user_data: consent ? 'granted' : 'denied',
      ad_personalization: consent ? 'granted' : 'denied',
    });
  }
};

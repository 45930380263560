import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { VFC } from 'react';

const NavbarToggleButton: VFC<{ open: boolean }> = ({ open }) => (
  <div className='z-50 -mr-2 flex lg:hidden' data-testid='navbar-toggle-btn'>
    {/* Mobile menu button */}
    <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-slate-800  active:ring-0'>
      <span className='sr-only'>Open main menu</span>
      {open ? (
        <XIcon className='block h-6 w-6' aria-hidden='true' />
      ) : (
        <MenuIcon className='block h-6 w-6' aria-hidden='true' />
      )}
    </Disclosure.Button>
  </div>
);

export default NavbarToggleButton;


// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppProps } from 'next/app';
import React from 'react';
import MainLayout from '../layouts/MainLayout';
import '../styles/globals.css';
import { Provider } from 'react-redux';
import store from '../app/store';
import { GeneralSEO } from '../components/GeneralSEO';

import { TagManager } from '../components/Cookies/TagManager';
import { SWRDevTools } from 'swr-devtools';

function CustomApp({
  Component,
  pageProps,
}: AppProps<{
  hasContainer: boolean;
}>) {
  return (
    <SWRDevTools>
      <Provider store={store}>
        <MainLayout
          hasContainer={pageProps.hasContainer ?? true}
          isFullScreen={true}
        >
          <TagManager />
          <GeneralSEO />
          <Component {...pageProps} />
        </MainLayout>
      </Provider>
    </SWRDevTools>
  );
}

const __Page_Next_Translate__ = CustomApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BuyModalState {
  isOpen: boolean;
}

const initialState: BuyModalState = {
  isOpen: false,
};

export const buyModalSlice = createSlice({
  name: 'buyModal',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = buyModalSlice.actions;

export default buyModalSlice.reducer;

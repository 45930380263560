const { getDomains } = require('./lib/server/getDomains');

module.exports = {
  locales: [
    'en-US',
    'fr-FR',
    'de-DE',
    'es-ES',
    'pl-PL',
    'it-IT',
    'cs-CZ',
    'en-GB',
  ],
  defaultLocale: 'en-US',
  domains: getDomains(process.env.NEXT_PUBLIC_APP_ENV),
  localeDetection: false,
  pages: {
    '*': ['common', 'seo'],
    '/privacy': ['privacy'],
    '/services/warehouse-service': ['services'],
    '/services/international-logistics': ['services'],
    '/services/global-marketing': ['services'],
    '/services/data-driven-pricing': ['services'],
    '/services/project-consult': ['services'],
  },
  defaultNS: 'common',
};

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import counterReducer from '../components/counter/counterSlice';
import productFilterReducer from '@/components/Form/ProductsFilter/productsFilterSlice';
import cookieConsentReducer from '@/components/Cookies/cookieConsentSlice';
import buyModalReducer from '@/components/Products/BuyModal/buyModalSlice';

export function makeStore() {
  return configureStore({
    reducer: {
      counter: counterReducer,
      productFilter: productFilterReducer,
      cookieConsent: cookieConsentReducer,
      buyModal: buyModalReducer,
    },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookieConsentValue } from 'react-cookie-consent';

export interface cookieConsent {
  hasConsent: boolean;
}

const initialState: cookieConsent = {
  hasConsent: !!getCookieConsentValue(),
};

export const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    setHasCookieConsent: (state, action: PayloadAction<boolean>) => {
      state.hasConsent = action.payload;
    },
  },
});

export const { setHasCookieConsent } = cookieConsentSlice.actions;

export default cookieConsentSlice.reducer;

import React from 'react';
import { DefaultSeo } from 'next-seo';
import { translateUrl } from '@/lib/server/translateURL';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

export function GeneralSEO() {
  const router = useRouter();
  const { t } = useTranslation();
  function getAllLanguageAlternates(path: string, inputLang: string) {
    const alts: {
      hrefLang: string;
      href: string;
    }[] = [];
    [
      'en-US',
      'en-GB',
      'de-DE',
      'fr-FR',
      'es-ES',
      'pl-PL',
      'it-IT',
      'cs-CZ',
      'x-default',
    ].forEach((lang) => {
      if (lang !== inputLang) {
        const translatedPath = translateUrl(
          path,
          lang == 'x-default' ? 'en-US' : lang,
          inputLang,
          true
        );
        alts.push({
          hrefLang: lang,
          href: translatedPath,
        });
      }
    });
    return alts;
  }
  return (
    <DefaultSeo
      title={t('common:SEO_COMMON_TITLE')}
      description={t('common:SEO_COMMON_DESCRIPTION')}
      languageAlternates={getAllLanguageAlternates(
        router.asPath,
        router.locale || 'en-US'
      )}
      openGraph={{
        type: 'website',
        locale: router.locale,
        site_name: 'BEVMAQ',
        images: [
          {
            url: process.env.NEXT_PUBLIC_IMAGES_URL + '/logo.jpg',
            alt: 'BEVMAQ',
          },
        ],
      }}
      twitter={{
        cardType: 'summary_large_image',
      }}
    />
  );
}

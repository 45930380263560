import { NavigationItem } from '@/lib/types/navigation.types';

export const userNavigation: NavigationItem[] = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export const navigation: NavigationItem[] = [
  { name: 'Sell Machines', href: '/sell', isCurrent: false },
  { name: 'Buy Machines', href: '/buy', isCurrent: false },
  {
    name: 'About',
    href: '/about',
    isCurrent: false,
    subitems: [
      { name: 'Company', href: '/company', isCurrent: false },
      { name: 'Jobs', href: '/jobs', isCurrent: false },
      { name: 'Press', href: '/press', isCurrent: false },
      { name: '#NowWithBevmaq', href: '/now-with-bevmaq', isCurrent: false },
    ],
  },
  {
    name: 'Services',
    href: '/services',
    isCurrent: false,
    subitems: [
      {
        name: 'Data-driven Pricing',
        href: '/services/data-driven-pricing',
        isCurrent: false,
      },
      {
        name: 'Global Marketing',
        href: '/services/global-marketing',
        isCurrent: false,
      },
      {
        name: 'International Logistics',
        href: '/services/international-logistics',
        isCurrent: false,
      },
      {
        name: 'Warehouse Service',
        href: '/services/warehouse-service',
        isCurrent: false,
      },
      {
        name: 'Project Consult',
        href: '/services/project-consult',
        isCurrent: false,
      },
    ],
  },
  { name: 'Contact', href: '/contact', isCurrent: false },
];
